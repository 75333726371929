import React, { useEffect, useState } from "react";
import { RegisterInformationForm } from "../../../utils/typeForm";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/common/input";
import { Button, message } from "antd";
import Upload from "../../../components/atoms/upload";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { getProfile, updateProfile, uploadImages } from "../../../store/features/profileSlice";
import { loading, unLoading } from "../../../store/features/spinSlice";
import { useNavigate } from "react-router-dom";

const RegisterInformation = () => {
  const key = "mess_register_infor"
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useAppSelector(state => state.profileSlice.data);
  const [images, setImages] = useState<File[]>([]);
  const [urlImages, setUrlImages] = useState<string[]>([]);
  const [error, setError] = useState(true);
  const [authen, setAuthen] = useState(false);
  const methods = useForm<RegisterInformationForm>({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const onRegisterInformation = async (values: RegisterInformationForm) => {
    if (!error) {
      dispatch(loading());
      const file_keys = images.length > 0 ? (await dispatch(uploadImages(images))).payload.map((data: any) => `${process.env.REACT_APP_BASE_URL}/file/${data.file_key}`) : [];
      dispatch(updateProfile({
        full_name: values.full_name,
        file_keys: [...file_keys, ...urlImages],
        birthday: values.birthday,
        phone_number: values.phone_number
      })).then((res) => {
        dispatch(unLoading());
        message.open({ type: "success", content: "Cập nhật thông tin thành công!", key, duration: 2 });
        setAuthen(true);
      }).catch((error) => {
        console.log(error);
        setAuthen(false);
        message.open({ type: "error", content: "Cập nhật thông tin thất bại", key, duration: 2 });
        dispatch(unLoading());
      })
    }
  };

  useEffect(() => {
    setError(images.length < 1 && urlImages.length < 1);
  }, [images, urlImages]);

  useEffect(() => {
    dispatch(getProfile());
  }, []);

  useEffect(() => {
    if (profile) {
      methods.reset({ ...profile });
      profile.file_keys && setUrlImages(profile.file_keys);
      setAuthen(!!profile.file_keys);
    }
  }, [profile]);

  useEffect(() => {
    authen && navigate("/");
  }, [authen]);

  return (
    <div className="container-register-information w-100 h-[100vh] flex flex-col items-center bg-gradient-to-r from-[#65799b] to-[#5e2563] pt-[50px] box-border">
      <div className="form-register-information w-[95%] max-w-[900px] h-[500px] min-h-[90%] bg-white box-border rounded-[8px] shadow-2xl">
        <h3 className="h-[50px] flex items-center px-[10px] w-[100%] box-border">Xác nhận thông tin để hoàn tất thủ tục</h3>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onRegisterInformation)} className="w-[100%] box-border flex flex-col" style={{ height: `calc(100% - 50px)` }}>
            <div className="flex-1 overflow-auto">
              <div className="flex flex-wrap items-start gap-[10px] p-[10px] box-border">
                <div className="infor-user">
                  <Field
                    name="full_name"
                    type="text"
                    label="Họ và tên"
                    labelClass="required"
                    placeholder="Tên họ và tên"
                    rules={{
                      required: {
                        value: true,
                        message: "Vui lòng nhập họ và tên"
                      }
                    }}
                  />
                  <Field
                    name="birthday"
                    type="text"
                    label="Ngày sinh"
                    labelClass="required"
                    placeholder="Định dang: ngày / tháng / năm"
                    rules={{
                      required: {
                        value: true,
                        message: "Vui lòng nhập ngày sinh"
                      }
                    }}
                  />
                </div>
                <div className="infor-user2">
                  <Field
                    name="phone_number"
                    type="text"
                    label="Số điện thoại"
                    labelClass="required"
                    placeholder="Số điện thoại"
                    rules={{
                      required: {
                        value: true,
                        message: "Vui lòng nhập số điện thoại"
                      }
                    }}
                  />
                  <Field
                    name="email"
                    type="text"
                    label="Email"
                    labelClass="required"
                    placeholder="Email"
                    disabled
                    rules={{
                      required: {
                        value: true,
                        message: "Vui lòng nhập email"
                      }
                    }}
                  />
                </div>
              </div>
              <div className="image-user px-[10px]">
                <p className="pb-[8px]">Thêm hình ảnh giấy tờ tùy thân</p>
                <Upload setImages={setImages} urlImages={urlImages} setUrlImages={setUrlImages} />
                {error &&
                  <p className="text-[red] text-[12px] mt-[5px] italic font-semibold">
                    Hình ảnh giấy tờ tùy thân là bắt buộc
                  </p>
                }
              </div>
            </div>
            <div className="flex h-[50px] box-border w-[100%] items-center gap-[20px] justify-end px-[20px]">
              <Button className="h-[32px]" type="primary" htmlType="submit" size="small" block>Xác nhận</Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  )
};

export default RegisterInformation;