import React, { useEffect, useState } from "react";
import "./style.scss";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { Dropdown, MenuProps } from "antd";
import { Epath } from "../../utils/Epath";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { localClearStorage, localGetItem } from "../../utils/storage";
import { clearAuth } from "../../store/features/authSlice";
import { clearProfile, getProfile } from "../../store/features/profileSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import SidebarMobile from "../../components/common/sidebarMoblie";

const UserLayout = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const profile = useAppSelector(state => state.profileSlice.data);
   const [showSidebarMobile, setShowSidebarMobile] = useState(false);

   const logOut = () => {
      localClearStorage();
      dispatch(clearAuth());
      dispatch(clearProfile());
      navigate(Epath.LOGIN);
   };

   const onShow = () => {
      setShowSidebarMobile(!showSidebarMobile);
   };

   const items: MenuProps['items'] = [
      {
         key: 1,
         label: <p className="text-[13px]" onClick={() => navigate(Epath.PROFILE)}>Cá nhân</p>
      },
      {
         key: 2,
         label: <p className="text-[13px]" onClick={() => navigate(Epath.CHANGE_PASSWORD)}>Đổi mật khẩu</p>
      },
      {
         key: 3,
         label: <p className="text-[13px]" onClick={logOut}>Đăng xuất</p>
      }
   ];

   useEffect(() => {
      dispatch(getProfile());
   }, []);

   return (
      <>
         {showSidebarMobile && <SidebarMobile callBack={onShow} />}
         <div className="w-[100%] h-[100vh] box-border overflow-hidden">
            <div className="flex items-center justify-center h-[52px] sticky top-0 bg-white shadow-2xl box-border">
               <div className="header-user-layout flex justify-between max-w-[1024px] px-[20px]">
                  <FontAwesomeIcon
                     className="ml-[5px] cursor-pointer icon-bars-user-layout"
                     icon={faBars} style={{ fontSize: "22px" }}
                     onClick={onShow}
                  />
                  <div className="menu-header-user-layout gap-[30px]">
                     <NavLink to={Epath.HOME} className="font-semibold"
                        style={{ color: "#000" }}
                     >Đăng ký khoản vay</NavLink>
                     <NavLink to={Epath.REGISTER_MONEY} className="font-semibold"
                        style={{ color: "#000" }}
                     >Lịch sử khoản vay</NavLink>
                  </div>
                  <Dropdown menu={{ items }} trigger={["click"]} className="mr-[10px]">
                     <span className="cursor-pointer font-semibold">{profile?.full_name} <FontAwesomeIcon icon={faCaretDown} /></span>
                  </Dropdown>
               </div>
            </div>
            <div className="w-[100%] flex justify-center box-border overflow-auto" style={{ height: 'calc(100vh - 52px)' }}><Outlet /></div>
         </div>
      </>
   )
};

export default UserLayout;