import React, { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { Epath } from "../../../utils/Epath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowLeft, faUser } from "@fortawesome/free-solid-svg-icons";

const SidebarMobile = ({
    callBack
}: {
    callBack?: () => void;
}) => {
    return (
        <div className="w-full h-[100vh] fixed top-0 left-0 bg-[#00000085] z-[999]">
            <div className="absolute top-0 left-0 w-[200px]">
                <div className="w-[180px] h-[100vh] bg-[#ffffff]">
                    <div className="h-[52px] flex items-center justify-center box-border">
                        <FontAwesomeIcon icon={faUser} fontSize={30} />
                    </div>
                    <div className="flex flex-col w-[100%] overflow-auto"
                        style={{ height: "calc(100vh - 102px)" }}
                    >
                        <NavLink
                            onClick={callBack}
                            to={Epath.HOME}
                            className="font-semibold pl-[10px]"
                            style={{ height: "36px", color: "#000", width: "100%", display: "flex", alignItems: "center", boxSizing: "border-box" }}
                        >Đăng ký khoản vay</NavLink>
                        <NavLink
                            onClick={callBack}
                            to={Epath.REGISTER_MONEY}
                            className="font-semibold pl-[10px]"
                            style={{ height: "36px", color: "#000", width: "100%", display: "flex", alignItems: "center", boxSizing: "border-box" }}
                        >Lịch sử khoản vay</NavLink>
                    </div>
                    <div className="w-full flex justify-center">
                        <FontAwesomeIcon
                            icon={faCircleArrowLeft}
                            color="#000"
                            fontSize={28}
                            onClick={callBack}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};

export default SidebarMobile;