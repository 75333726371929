import React, { useEffect, useState } from "react";
// import { RegisterInformationForm } from "../../../utils/typeForm";
import { FormProvider, useForm } from "react-hook-form";
// import Field from "../../../components/common/input";
import { Button, message } from "antd";
// import Upload from "../../../components/atoms/upload";
// import "./style.scss";
// import { useAppDispatch, useAppSelector } from "../../../store/hook";
// import { getProfile, updateProfile, uploadImages } from "../../../store/features/profileSlice";
// import { loading, unLoading } from "../../../store/features/spinSlice";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { RegisterInformationForm } from "../../utils/typeForm";
import { loading, unLoading } from "../../store/features/spinSlice";
import { getProfile, updateProfile, uploadImages } from "../../store/features/profileSlice";
import Field from "../../components/common/input";
import Upload from "../../components/atoms/upload";

const Profile = () => {
    const key = "mess_register_infor";
    const dispatch = useAppDispatch();
    const profile = useAppSelector(state => state.profileSlice.data);
    const [images, setImages] = useState<File[]>([]);
    const [urlImages, setUrlImages] = useState<string[]>([]);
    const [error, setError] = useState(true);
    const [authen, setAuthen] = useState(false);
    const methods = useForm<RegisterInformationForm>({
        mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
    });

    const onRegisterInformation = async (values: RegisterInformationForm) => {
        if (!error) {
            dispatch(loading());
            const file_keys = images.length > 0 ? (await dispatch(uploadImages(images))).payload.map((data: any) => `${process.env.REACT_APP_BASE_URL}/file/${data.file_key}`) : [];
            dispatch(updateProfile({
                full_name: values.full_name,
                file_keys: [...file_keys, ...urlImages],
                birthday: values.birthday,
                phone_number: values.phone_number
            })).then((res) => {
                dispatch(unLoading());
                message.open({ type: "success", content: "Cập nhật thông tin thành công!", key, duration: 2 });
                setAuthen(true);
            }).catch((error) => {
                console.log(error);
                setAuthen(false);
                message.open({ type: "error", content: "Cập nhật thông tin thất bại", key, duration: 2 });
                dispatch(unLoading());
            })
        }
    };

    useEffect(() => {
        setError(images.length < 1 && urlImages.length < 1);
    }, [images, urlImages]);

    useEffect(() => {
        dispatch(getProfile());
    }, []);

    useEffect(() => {
        if (profile) {
            methods.reset({ ...profile });
            profile.file_keys && setUrlImages(profile.file_keys);
        }
    }, [profile]);

    return (
        <div className="container-information w-[100%] flex flex-col items-center pt-[50px] box-border">
            <div className="w-[100%] max-w-[1024px]">
                <h3 className="h-[50px] flex items-center px-[10px] w-[100%] box-border">Thông tin cá nhân</h3>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(onRegisterInformation)} className="w-[100%] box-border flex flex-col">
                        <div className="flex-1 overflow-auto">
                            <div className="flex flex-wrap items-start gap-[10px] h-[100%] p-[10px] overflow-auto box-border">
                                <div className="infor-user">
                                    <Field
                                        name="full_name"
                                        type="text"
                                        label="Họ và tên"
                                        labelClass="required"
                                        placeholder="Tên họ và tên"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Vui lòng nhập họ và tên"
                                            }
                                        }}
                                    />
                                    <Field
                                        name="birthday"
                                        type="text"
                                        label="Ngày sinh"
                                        labelClass="required"
                                        placeholder="Định dang: ngày / tháng / năm"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Vui lòng nhập ngày sinh"
                                            }
                                        }}
                                    />
                                </div>
                                <div className="infor-user2">
                                    <Field
                                        name="phone_number"
                                        type="text"
                                        label="Số điện thoại"
                                        labelClass="required"
                                        placeholder="Số điện thoại"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Vui lòng nhập số điện thoại"
                                            }
                                        }}
                                    />
                                    <Field
                                        name="email"
                                        type="text"
                                        label="Email"
                                        labelClass="required"
                                        placeholder="Email"
                                        disabled
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "Vui lòng nhập email"
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="image-user px-[10px]">
                                <p className="pb-[8px]">Thêm hình ảnh giấy tờ tùy thân</p>
                                <Upload setImages={setImages} urlImages={urlImages} setUrlImages={setUrlImages} />
                                {error &&
                                    <p className="text-[red] text-[12px] mt-[5px] italic font-semibold">
                                        Hình ảnh giấy tờ tùy thân là bắt buộc
                                    </p>
                                }
                            </div>
                        </div>
                        <div className="flex h-[50px] box-border w-[100%] items-center gap-[20px] justify-end px-[20px]">
                            <Button className="h-[32px]" type="primary" htmlType="submit" size="small">Xác nhận</Button>
                        </div>
                    </form>
                </FormProvider>
            </div>
        </div>
    )
};

export default Profile;