import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/common/input";
import { LoginForm } from "../../../utils/typeForm";
import { Button, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { login, loginFacebook } from "../../../store/features/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import { Epath } from "../../../utils/Epath";
import { localSetItem } from "../../../utils/storage";
import { getProfile } from "../../../store/features/profileSlice";
import "./style.scss";
import { signInWithPopup, FacebookAuthProvider } from "firebase/auth";
import { auth, provider } from "../base";
import logo from "../../../assets/images/logo.png";

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [loginOk, setLoginOk] = useState(false);
  const entity = useAppSelector((state) => state.authSlice.entity);
  const error = useAppSelector((state) => state.authSlice.error);
  const profile = useAppSelector((state) => state.profileSlice.data);
  const methods = useForm<LoginForm>({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onChange",
  });
  const key = "messlogin";

  const onLogin = (value: LoginForm) => {
    const params = {
      email: value.email,
      password: value.password,
    };
    dispatch(login(params));
  };

  useEffect(() => {
    if (entity) {
      localSetItem("token", entity.access_token);
      localSetItem("refresh_token", entity.refresh_token);
      dispatch(getProfile());
    }
  }, [entity]);

  useEffect(() => {
    if (error) {
      message.open({ type: "error", content: error.errors[0], key, duration: 2 });
    }
  }, [error]);

  useEffect(() => {
    if (profile) {
      message.open({
        type: "success",
        content: "Đăng nhập thành công!",
        key,
        duration: 2,
      });
      localSetItem("role", profile.roles);
      localSetItem("user", profile.full_name);
      setLoginOk(true);
    }
  }, [profile]);

  useEffect(() => {
    if (loginOk) {
      profile?.roles.includes("USER") && !profile?.file_keys
        ? navigate(Epath.REGISTER_INFORMATION)
        : navigate(Epath.HOME);
    }
  }, [loginOk]);

  const loginFace = () => {
    signInWithPopup(auth, provider)
      .then((result) => {
        const user = result.user;
        const dataParam = {
          email: user.email as string,
          type: "FACEBOOK",
        };
        const response = dispatch(loginFacebook(dataParam));
        const credential = FacebookAuthProvider.credentialFromResult(result);
      })
      .catch((error) => {
        console.log("error", error);
        const errorCode = error.code;
        const errorMessage = error.message;

        const email = error.customData.email;
        const credential = FacebookAuthProvider.credentialFromError(error);
      });
  };

  return (
    <div className="container-login w-100 h-[100vh] flex flex-col items-center pt-[70px] box-border">
      <img src={logo} alt="Logo" className="logo" />
      <div className="form-login xs:w-[350px] xm:w-[390px] box-border rounded-[8px] shadow-2xl flex flex-col items-center p-[20px]">
        <h3 className="text-center mt-[20px]">Đăng nhập</h3>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onLogin)}
            className="mt-[30px] w-full"
          >
            <Field
              name="email"
              type="text"
              label="Tên đăng nhập"
              labelClass="required"
              placeholder="Tên đăng nhập"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập tên đăng nhập",
                },
              }}
            />
            <Field
              name="password"
              type="password"
              label="Mật khẩu"
              labelClass="required"
              autoComplete="none"
              placeholder="Mật khẩu"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập mật khẩu",
                },
              }}
            />
            <div className="flex items-center justify-between">
              <NavLink to="/register">Đăng ký tài khoản</NavLink>
              <NavLink to="/forgot-passoword">Quên mật khẩu</NavLink>
            </div>
            <Button
              className="h-[40px] mt-[20px]"
              type="primary"
              htmlType="submit"
              block
            >
              Đăng nhập
            </Button>
            <div className="textor">
              <p className="textstyle">hoặc</p>
            </div>
            <Button
              className="facebook-login-button"
              onClick={() => loginFace()}
            >
              Đăng nhập bằng Facebook
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default Login;
