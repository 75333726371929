import { RouterProvider, createBrowserRouter } from "react-router-dom";
import PrivateRouter from "./PrivateRouter";
import AdminLayout from "../layout";
import { Epath } from "../utils/Epath";
import Profile from "../pages/profile";
import Login from "../pages/auth/login";
import SuspenseFallback from "../components/atoms/suspenseFallback";
import { useAppSelector } from "../store/hook";
import Register from "../pages/auth/register";
import RegisterInformation from "../pages/auth/registerInformation";
import { localGetItem } from "../utils/storage";
import { ROLE } from "../utils/enum";
import HomeUser from "../pages/user/home";
import HomeAdmin from "../pages/admin";
import RegisterMoney from "../pages/user/register-money";
import UserLayout from "../layout/userLayout";
import LoanManagerUpdate from "../pages/admin/loan-manager-update";
import ForgotPassword from "../pages/auth/forgotPassword";
import ChangePassword from "../pages/auth/changePassword";

const RootRouter = () => {
    const isLoading = useAppSelector(state => state.spinSlice.value);
    const role = localGetItem("role");

    const router = createBrowserRouter([
        role && role === ROLE.ADMIN ? {
            path: "/",
            element: (
                <PrivateRouter>
                    <AdminLayout />
                </PrivateRouter>
            ),
            children: [
                {
                    path: Epath.HOME,
                    element: <HomeAdmin />
                },
                {
                    path: `${Epath.LOAN_MANAGER_UPDATE}/:loan_application_id`,
                    element: <LoanManagerUpdate />
                },
                {
                    path: Epath.PROFILE,
                    element: <Profile />
                },
                {
                    path: Epath.CHANGE_PASSWORD,
                    element: <ChangePassword />
                },
            ]
        } : {
            path: "/",
            element: (
                <PrivateRouter>
                    <UserLayout />
                </PrivateRouter>
            ),
            children: [
                {
                    path: Epath.HOME,
                    element: <HomeUser />
                },
                {
                    path: Epath.REGISTER_MONEY,
                    element: <RegisterMoney />
                },
                {
                    path: Epath.PROFILE,
                    element: <Profile />
                },
                {
                    path: Epath.CHANGE_PASSWORD,
                    element: <ChangePassword />
                },
            ]
        },
        {
            path: Epath.LOGIN,
            element: <Login />
        },
        {
            path: Epath.REGISTER,
            element: <Register />
        },
        {
            path: Epath.FORGOT_PASSWORD,
            element: <ForgotPassword />
        },
        role && role === ROLE.USER ? {
            path: Epath.REGISTER_INFORMATION,
            element: <PrivateRouter><RegisterInformation /></PrivateRouter>
        } : {},
    ]);

    return (
        <>
            <RouterProvider
                router={router}
                fallbackElement={<SuspenseFallback />}
            />
            {isLoading && <SuspenseFallback />}
        </>
    )
}

export default RootRouter;
