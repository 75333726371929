import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/common/input";
import { Button, message } from "antd";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { NavLink, useNavigate } from "react-router-dom";
import { Epath } from "../../../utils/Epath";
import "./style.scss";
import logo from "../../../assets/images/logo.png";
import { forgotPassword } from "../../../store/features/authSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const methods = useForm<{ email: string }>({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onChange",
  });
  const key = "messlogin";

  const onForgotPassword = async (value: { email: string }) => {
    const params = {
      email: value.email,
    };
    await dispatch(forgotPassword(params)).then((res) => {
      if (res.payload.errors) {
        const err = res.payload.errors;
        message.open({ type: "error", content: err[0], key, duration: 2 });
      } else {
        message.open({ type: "success", content: "Yêu cầu thành công, hãy kiểm tra email của bạn", key, duration: 2 });
      }
    }).catch((error) => {
      message.open({ type: "error", content: "Yêu cầu thất bại", key, duration: 2 });
    })
  };

  return (
    <div className="container-login w-100 h-[100vh] flex flex-col items-center pt-[70px] box-border">
      <img src={logo} alt="Logo" className="logo" />
      <div className="form-login xs:w-[350px] xm:w-[390px] box-border rounded-[8px] shadow-2xl flex flex-col items-center p-[20px]">
        <h3 className="text-center mt-[20px]">Quên mật khẩu</h3>
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(onForgotPassword)}
            className="mt-[30px] w-full"
          >
            <Field
              name="email"
              type="text"
              label="Nhập email của bạn"
              labelClass="required"
              autoComplete="none"
              placeholder="Email của bạn"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập email của bạn",
                },
              }}
            />
            <div className="flex items-center justify-between">
              <NavLink to="/login">Đăng nhập</NavLink>
              <NavLink to="/register">Đăng ký tài khoản</NavLink>
            </div>
            <Button
              className="h-[40px] mt-[20px]"
              type="primary"
              htmlType="submit"
              block
            >
              Xác nhận
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default ForgotPassword;
