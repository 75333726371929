import { Table, TablePaginationConfig, TableProps, Tag, Pagination, Button, Tooltip, PaginationProps, Modal, message, Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import TitlePage from "../../components/atoms/titlePage";
import { LabelStateProject, StateProject } from "../../utils/enum";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { faMagnifyingGlass, faSort } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../store/hook";
import { useNavigate } from "react-router-dom";
import { Epath } from "../../utils/Epath";
import TextArea from "antd/es/input/TextArea";
import { useDebounce } from "../../utils/customHook/useDebounce";
import CustomPopover from "../../components/common/popover/customPopover";
import { TInfoLoan, confirmLoan, getLoansByAdmin } from "../../store/features/loanSlice";
import { convertMoney } from "../../utils/func";
import dayjs from "dayjs";

type TableParams = {
  pagination?: TablePaginationConfig;
}

const HomeAdmin = () => {
  const key = "messLoans";
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loanSlice = useAppSelector(state => state.loanSlice);
  const [modalReject, setModalReject] = useState(false);
  const [errorReject, setErrorReject] = useState(false);
  const [rejectReason, setRejectReason] = useState<string>("");
  const [loanSelect, setLoanSelect] = useState<TInfoLoan>();
  const [type, setType] = useState<"approve" | "reject">("approve");
  const [searchCustomer, setSearchCustomer] = useState("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
  const [searchState, setSearchState] = useState();
  const debouncedCustomer = useDebounce<string>(searchCustomer, 500);
  const debouncedPhoneNumber = useDebounce<string>(searchPhoneNumber, 500);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10
    }
  });

  const showModalConfirm = (record: TInfoLoan, type: "approve" | "reject") => {
    if (type === "reject") {
      setRejectReason("");
      setErrorReject(false);
    }
    setType(type);
    setLoanSelect(record);
    setModalReject(true);
  };

  const closeModalConfirm = () => {
    setModalReject(false);
  }

  const columns: TableProps['columns'] = [
    {
      title: (
        <div className="flex justify-between items-center">
          STT <FontAwesomeIcon icon={faSort} />
        </div>
      ),
      dataIndex: "stt",
      key: "stt",
      width: "80px",
      render: (_, record, index) => index + 1
    },
    {
      title: (
        <div className="flex justify-between items-center">
          Người vay
          <CustomPopover
            content={<>
              <Input
                placeholder="Tên người vay"
                name="searchUser"
                allowClear
                onChange={(e) => setSearchCustomer(e.target.value)}
              />
            </>}
            title="Tìm kiếm người vay"
            icon={faMagnifyingGlass}
            placement="bottomRight"
          />
        </div>
      ),
      dataIndex: "customer_name",
      key: "customer_name",
      width: "250px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: (
        <div className="flex justify-between items-center">
          Số điện thoại
          <CustomPopover
            content={<>
              <Input
                placeholder="Số điện thoại"
                name="searchPhoneNumber"
                allowClear
                onChange={(e) => setSearchPhoneNumber(e.target.value)}
              />
            </>}
            title="Tìm kiếm số điện thoại"
            icon={faMagnifyingGlass}
            placement="bottomRight"
          />
        </div>
      ),
      dataIndex: "phone_number",
      key: "phone_number",
      width: "125px",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Số tiền vay",
      dataIndex: "loan_number",
      key: "loan_number",
      width: "120px",
      render: (value, record, index) => `${convertMoney(value)} USD`
    },
    {
      title: "Thời hạn vay",
      dataIndex: "borrowed_time",
      key: "borrowed_time",
      width: "120px",
      render: (value, record, index) => `${value} tháng`
    },
    {
      title: "Ngày vay",
      dataIndex: "created_at",
      key: "created_at",
      width: "120px",
      render: (value, record, index) => value && dayjs(value).format('DD/MM/YYYY')
    },
    {
      title: (
        <div className="flex justify-between items-center">
          Trạng thái
          <CustomPopover
            content={<>
              <Select
                options={[
                  { label: LabelStateProject.STATE_PROCESSING, value: StateProject.STATE_PROCESSING },
                  { label: LabelStateProject.STATE_APPROVE, value: StateProject.STATE_APPROVE },
                  { label: LabelStateProject.STATE_REJECT, value: StateProject.STATE_REJECT }
                ]}
                allowClear
                onChange={(value) => setSearchState(value)}
                style={{ width: "100%" }}
                placeholder="Chọn trạng thái"
              />
            </>}
            title="Tìm kiếm theo trạng thái"
            icon={faMagnifyingGlass}
            placement="bottomRight"
          />
        </div>
      ),
      dataIndex: "status",
      key: "status",
      width: "150px",
      align: "center",
      render: (_, { status }) => (
        <Tag color={status === StateProject.STATE_APPROVE ? "#87d068"
          : (status === StateProject.STATE_REJECT ? "#f50" : "#108ee9")}
        >
          {status === StateProject.STATE_APPROVE ? "Chấp thuận"
            : (status === StateProject.STATE_REJECT ? "Từ chối" : "Chờ duyệt")}
        </Tag>
      )
    },
    {
      title: "Lí do từ chối",
      dataIndex: "reason",
      key: "reason",
      // width: "200px",
      render: (value, record, index) => (
        <div className="w-[100%] text-ellipsis overflow-hidden whitespace-nowrap">{value}</div>
      )
    },
    {
      title: "Thao tác",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      width: "100px",
      render: (_, record) => (
        <div className="flex items-center gap-[8px] justify-center">
          <Tooltip title="Chấp thuận">
            <Button
              type="primary"
              style={{ backgroundColor: "#87d068" }} size="small"
              disabled={record.state === StateProject.STATE_APPROVE}
              onClick={(e) => {
                e.stopPropagation();
                showModalConfirm(record, "approve");
              }}
            >
              <FontAwesomeIcon icon={faCircleCheck} fontSize={16} />
            </Button>
          </Tooltip>
          <Tooltip title="Từ chối">
            <Button
              type="primary"
              style={{ backgroundColor: "red" }} size="small"
              disabled={record.state === StateProject.STATE_REJECT}
              onClick={(e) => {
                e.stopPropagation();
                showModalConfirm(record, "reject");
              }}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                fontSize={16}
              />
            </Button>
          </Tooltip>
        </div>
      )
    },
  ];

  const getAllEntities = () => {
    dispatch(getLoansByAdmin({
      page: tableParams.pagination?.current && (tableParams.pagination?.current - 1),
      size: tableParams.pagination?.pageSize,
      customer_name: searchCustomer,
      phone_number: searchPhoneNumber,
      status: searchState
    }))
  };

  const onChangePagination: PaginationProps['onChange'] = (page, pageSize) => {
    setTableParams({
      ...tableParams,
      pagination: {
        current: page,
        pageSize: pageSize
      }
    })
  };

  const onChangeReason = (value: string) => {
    setErrorReject(!(value.length > 0));
    setRejectReason(value);
  };

  const onSubmitConfirm = () => {
    const params = {
      id: loanSelect?.loan_application_id,
      status: type === "reject" ? StateProject.STATE_REJECT : StateProject.STATE_APPROVE,
      reason: rejectReason
    };
    let error = false;
    if (type === "reject") {
      if (!(rejectReason.length > 0)) {
        setErrorReject(true);
        error = true;
      }
    }
    if (!error) {
      dispatch(confirmLoan(params)).then((res) => {
        message.open({ type: "success", content: res.payload.message, key, duration: 2 });
        closeModalConfirm();
        getAllEntities();
      }).catch((error) => {
        message.open({ type: "error", content: error.message, key, duration: 2 });
      })
    }
  }

  useEffect(() => {
    getAllEntities();
  }, [JSON.stringify(tableParams), debouncedCustomer, debouncedPhoneNumber, searchState]);

  return (
    <div className="h-full">
      <TitlePage title="Quản lý các khoản vay" />
      <div style={{ height: "calc(100% - 106px)" }}>
        <div className="custom-table h-full overflow-auto">
          <Table
            columns={columns}
            dataSource={loanSlice?.data.length > 0 ? loanSlice.data : []}
            size="small"
            rowKey={record => record.loan_application_id}
            pagination={false}
            onRow={(record) => ({
              onClick: () => navigate(`${Epath.LOAN_MANAGER_UPDATE}/${record.loan_application_id}`),
            })}
          />
        </div>
        <div className="h-[56px] flex items-center justify-end px-[20px]">
          <Pagination
            total={loanSlice.total_item}
            defaultCurrent={1}
            pageSizeOptions={[10, 20, 30, 50]}
            showSizeChanger={true}
            onChange={onChangePagination}
          />
        </div>
      </div>
      <Modal
        title={`${type === "approve" ? "Duyệt khoản vay" : "Từ chối khoản vay"}`}
        open={modalReject}
        onCancel={closeModalConfirm}
        onOk={onSubmitConfirm}
        width={400}
        okText="Lưu"
        cancelText="Hủy bỏ"
      >
        {type === "reject"
          ? <>
            <h4 className="text-center my-[30px]">Vui lòng nhập lý do từ chối khoản vay</h4>
            <TextArea
              value={rejectReason}
              rows={4}
              placeholder="Lý do..."
              status={errorReject ? 'error' : undefined}
              onChange={(e) => { onChangeReason(e.target.value) }}
            />
            {errorReject && <p className='text-[red] mt-[4px] text-[12px]'>Yêu cầu nhập lý do</p>}
          </>
          : <h4 className="text-center my-[30px]">Xác nhận duyệt khoản vay</h4>
        }
      </Modal>
    </div>
  )
};

export default HomeAdmin;