import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axios/axiosClient";
import { localDeleteItem } from "../../utils/storage";
import { loading, unLoading } from "./spinSlice";

export type ROLE = "ADMIN" | "USER";

export type TProfile = {
   phone_number: string;
   full_name: string;
   email: string;
   birthday: string;
   file_keys: string[] | null;
   roles: ROLE[];
};

type TInitialState = {
   data: TProfile | null;
};

const initialState: TInitialState = {
   data: null
};

export const uploadImages = createAsyncThunk(
   'upload_images',
   async (files: any, thunkApi) => {
      try {
         const formData = new FormData();
         for (let i = 0; i < files.length; i++) {
            formData.append('files', files[i]);
         }
         const response = await axiosClient.post('/file/upload', formData, {
            headers: {
               'Content-Type': 'multipart/form-data'
            }
         });
         return response.data;
      } catch (error: any) {
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const getProfile = createAsyncThunk(
   'profile/get-profile',
   async (_, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const respone = await axiosClient.get('/user');
         thunkApi.dispatch(unLoading());
         return respone.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const updateProfile = createAsyncThunk(
   'profile/put-profile',
   async (params: {full_name: string; birthday: string; phone_number: string; file_keys: string[] | null;}, thunkApi) => {
      try {
         const respone = await axiosClient.put('/user', params);
         return respone.data;
      } catch (error: any) {
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
)

export const profileSlice = createSlice({
   name: 'profile',
   initialState,
   reducers: {
      clearProfile: (state) => {
         state.data = null;
         localDeleteItem("role");
      }
   },
   extraReducers(builder) {
      builder.addCase(getProfile.fulfilled, (state, action) => {
         state.data = action.payload;
      })
   },
});

export const { clearProfile } = profileSlice.actions;

export default profileSlice.reducer;