import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import Field from "../../../components/common/input";
import { Button, message } from "antd";
import { useAppDispatch } from "../../../store/hook";
import { changePassword } from "../../../store/features/authSlice";

const ChangePassword = () => {
   const key = "mess";
   const dispatch = useAppDispatch();
   const methods = useForm<{ old_password: string; new_password: string; confirm_password: string }>({
      mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
   });

   const onChangePassword = async (value: { old_password: string; new_password: string; confirm_password: string }) => {
      await dispatch(changePassword(value)).then((res) => {
         if (res.payload.errors) {
            const err = res.payload.errors;
            message.open({ type: "error", content: err[0], key, duration: 2 });
         } else {
            message.open({ type: "success", content: res.payload.message, key, duration: 2 });
         }
      }).catch((error) => {
         message.open({ type: "error", content: "Yêu cầu thất bại", key, duration: 2 });
      })
   }

   return (
      <div className="w-full max-w-[1024px] box-border">
         <h3 className="h-[50px] flex items-center px-[10px] w-[100%] box-border">Thay đổi mật khẩu</h3>
         <div className="w-[100%] flex justify-center">
            <FormProvider {...methods}>
               <form onSubmit={methods.handleSubmit(onChangePassword)} className="mt-[30px] w-[300px]">
                  <Field
                     name="old_password"
                     type="password"
                     label="Mật khẩu của bạn"
                     labelClass="required"
                     autoComplete="none"
                     placeholder="Nhập mật khẩu của bạn"
                     rules={{
                        required: {
                           value: true,
                           message: "Vui lòng nhập mật khẩu của bạn"
                        }
                     }}
                  />
                  <Field
                     name="new_password"
                     type="password"
                     label="Mật khẩu mới"
                     labelClass="required"
                     autoComplete="none"
                     placeholder="Nhập mật khẩu mới"
                     rules={{
                        required: {
                           value: true,
                           message: "Vui lòng nhập mật khẩu mới"
                        }
                     }}
                  />
                  <Field
                     name="confirm_password"
                     type="password"
                     label="Mật khẩu"
                     labelClass="required"
                     autoComplete="none"
                     placeholder="Xác nhận mật khẩu"
                     rules={{
                        required: {
                           value: true,
                           message: "Vui lòng nhập xác nhận mật khẩu"
                        },
                        validate: (value: string) => {
                           return methods.watch('new_password') ? value === methods.watch('new_password') || "Không khớp với mật khẩu mới" : null;
                        }
                     }}
                  />
                  <Button
                     className="h-[32px] mt-[20px]"
                     type="primary"
                     htmlType="submit"
                  >
                     Xác nhận
                  </Button>
               </form>
            </FormProvider>
         </div>
      </div>
   )
};

export default ChangePassword;