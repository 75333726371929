import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axios/axiosClient";
import { loading, unLoading } from "./spinSlice";

export type TInfoLoan = {
   loan_application_id: number;
   customer_name: string;
   phone_number: string;
   permanent_address: null | string;
   temporary_address: null | string;
   cic_no: null | string;
   date_range: null | string;
   loan_number: number;
   borrowed_time: number;
   birthday: string;
   email: string;
   file_keys: string[];
   status: 0 | 1 | 2;
   reason: string | null;
   created_at: string;
};

type TLoanSlice = {
   data: TInfoLoan[];
   total_item: number;
   loan: TInfoLoan | null;
}

const initialState: TLoanSlice = {
   data: [],
   total_item: 0,
   loan: null
}

export const registerLoan = createAsyncThunk(
   'register-loan',
   async (params: { loan_number: number; borrowed_time: number }, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosClient.post('/loan/register', params);
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const getListLoanAsync = createAsyncThunk(
   'get-list-loan',
   async ({ page, size }: { page?: number; size?: number; }, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosClient.get(`/loans?page=${page}&size=${size}`);
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const getLoansByAdmin = createAsyncThunk(
   'get-list-loan-by-admin',
   async ({ page, size, customer_name, phone_number, status }: { page?: number; size?: number; customer_name?: string, phone_number?: string, status?: number }, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosClient.get(`/admin/loans?page=${page}&size=${size}&name=${customer_name ?? ''}&phone_number=${phone_number ?? ''}&status=${status ?? ''}`);
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const getLoan = createAsyncThunk(
   "get_loan",
   async ({ id }: { id?: string }, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosClient.get(`/loan/${id}`);
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         console.log(error)
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

export const confirmLoan = createAsyncThunk(
   "confirm-loan",
   async (params: { id?: number; status: 0 | 1 | 2; reason: string; }, thunkApi) => {
      thunkApi.dispatch(loading());
      try {
         const response = await axiosClient.put(`/admin/loan/${params.id}`, params);
         thunkApi.dispatch(unLoading());
         return response.data;
      } catch (error: any) {
         thunkApi.dispatch(unLoading());
         console.log(error)
         return thunkApi.rejectWithValue(error.response.data)
      }
   }
);

const loandSlice = createSlice({
   name: 'loan',
   initialState,
   reducers: {},
   extraReducers(builder) {
      builder
         .addCase(getListLoanAsync.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.total_item = action.payload.total_item;
         })
         .addCase(getLoansByAdmin.fulfilled, (state, action) => {
            state.data = action.payload.data;
            state.total_item = action.payload.total_item;
         })
         .addCase(getLoan.fulfilled, (state, action) => {
            state.loan = action.payload;
         })
   },
});

export default loandSlice.reducer;