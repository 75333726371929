export const Epath = {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/forgot-passoword',
    CHANGE_PASSWORD: '/change-password',
    REGISTER_INFORMATION: '/register-information',
    HOME: '/',
    PROFILE: '/profile',
    REGISTER_MONEY: '/register-money',
    LOAN_MANAGER_UPDATE: '/loan'
}