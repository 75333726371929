// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { FacebookAuthProvider, getAuth } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyC4Er6F-IfnY746FGGiCy3n196gg3ITtoY",
    authDomain: "loginface-3a44d.firebaseapp.com",
    projectId: "loginface-3a44d",
    storageBucket: "loginface-3a44d.appspot.com",
    messagingSenderId: "455421919657",
    appId: "1:455421919657:web:a6bd7a7821af34f385c4b8",
    measurementId: "G-LMDDMZHQRV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new FacebookAuthProvider();
const analytics = getAnalytics(app);

export { provider, auth }; 