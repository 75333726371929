import { Dropdown, MenuProps } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Epath } from "../../../utils/Epath";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";
import { localClearStorage, localGetItem } from "../../../utils/storage";
import { useAppDispatch } from "../../../store/hook";
import { clearAuth } from "../../../store/features/authSlice";
import { ROLE } from "../../../utils/enum";
import { clearProfile } from "../../../store/features/profileSlice";

const Header = ({
    onClick
}: {
    onClick: () => void;
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = localGetItem("user");
    const role = localGetItem("role");

    const logOut = () => {
        localClearStorage();
        dispatch(clearAuth());
        dispatch(clearProfile());
        navigate(Epath.LOGIN);
    }

    const items: MenuProps['items'] = [
        {
            key: 1,
            label: <p className="text-[13px]" onClick={() => navigate(Epath.PROFILE)}>Cá nhân</p>
        },
        {
            key: 2,
            label: <p className="text-[13px]" onClick={() => navigate(Epath.CHANGE_PASSWORD)}>Đổi mật khẩu</p>
        },
        {
            key: 3,
            label: <p className="text-[13px]" onClick={logOut}>Đăng xuất</p>
        }
    ]

    return (
        <div className="flex items-center justify-between p-2 sticky-top bg-[#ffffff] shadow-2xl h-[52px] box-border">
            <div className="flex items-center gap-[12px]">
                <div className="ml-[5px] cursor-pointer icon-bars" onClick={onClick}>
                    <FontAwesomeIcon icon={faBars} style={{fontSize: "22px"}}/>
                </div>
                {role && role === ROLE.ADMIN && <h3>Quản lý tài chính</h3>}
            </div>
            <Dropdown menu={{ items }} trigger={["click"]} className="mr-[10px]">
                <span className="cursor-pointer font-semibold">{user} <FontAwesomeIcon icon={faCaretDown} /></span>
            </Dropdown>
        </div>
    )
};

export default Header;