import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosClient from "../../axios/axiosClient";
import { loading, unLoading } from "./spinSlice";
import { LoginForm, RegisterForm } from "../../utils/typeForm";

export type TDataLogin = {
    access_token: string;
    refresh_token: string;
}

type TypeLoginSlice = {
    entity: TDataLogin | null;
    error: any;
};

const initialState: TypeLoginSlice = {
    entity: null,
    error: null,
};

export const login = createAsyncThunk(
    'auth/login',
    async (params: LoginForm, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const respone = await axiosClient.post("/user/login", params)
            thunkApi.dispatch(unLoading());
            return respone.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const register = createAsyncThunk(
    'auth/register',
    async (params: RegisterForm, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const respone = await axiosClient.post("/user/register", params)
            thunkApi.dispatch(unLoading());
            return respone.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const forgotPassword = createAsyncThunk(
    'auth/forgot-password',
    async (params: { email: string }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const respone = await axiosClient.post("/user/reset-password", params)
            thunkApi.dispatch(unLoading());
            return respone.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const changePassword = createAsyncThunk(
    'auth/forgot-password',
    async (params: { old_password: string; new_password: string; confirm_password: string }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const respone = await axiosClient.put("/user/change-password", params)
            thunkApi.dispatch(unLoading());
            return respone.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);

export const loginFacebook = createAsyncThunk(
    'auth/login-face',
    async (params: {
        email: string;
        type: string;
    }, thunkApi) => {
        thunkApi.dispatch(loading());
        try {
            const respone = await axiosClient.post("/user/login-by-facebook", params)
            thunkApi.dispatch(unLoading());
            return respone.data;
        } catch (error: any) {
            thunkApi.dispatch(unLoading());
            console.log(error)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
);


const authSlice = createSlice({
    name: 'auth',
    initialState: initialState,
    reducers: {
        clearAuth: (state) => {
            state.entity = null;
            state.error = null;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(login.fulfilled, (state, action) => {
                state.entity = action.payload;
            })
            .addCase(login.rejected, (state, action) => {
                state.error = action.payload;
            })
            .addCase(loginFacebook.fulfilled, (state, action) => {
                state.entity = action.payload;
            })
            .addCase(loginFacebook.rejected, (state, action) => {
                state.error = action.payload;
            })
    },
})

export const { clearAuth } = authSlice.actions;

export default authSlice.reducer;