import React, { ChangeEvent, Dispatch, memo, useEffect, useRef, useState } from "react";
import "./style.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage } from "@fortawesome/free-solid-svg-icons";
import { Image } from "antd";
import { faCircleXmark } from "@fortawesome/free-regular-svg-icons";

const Upload = ({
  setImages, urlImages, setUrlImages
}: {
  setImages: Dispatch<React.SetStateAction<File[]>>;
  urlImages: string[];
  setUrlImages: Dispatch<React.SetStateAction<string[]>>;
}) => {
  const refFile = useRef<HTMLInputElement>(null);
  const [slider, setSlider] = useState<{ id: string; url: string }[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const handleSelectImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const files = Array.from(e.target.files);
      const selectedImagesArray = files.map((file) => ({ id: file.name, url: URL.createObjectURL(file) }));
      setSlider((prevImages) => prevImages.concat(selectedImagesArray));
      setFiles((file) => file.concat(files));
    }
  };

  const handleDeleteImage = (id: string) => {
    setSlider((prevImages) => prevImages.filter((prevImage) => prevImage.id !== id));
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== id));
  }

  const handleDeleteImageProps = (image: string) => {
    setUrlImages((prevImages) => prevImages.filter((prevImage) => prevImage !== image))
  }

  const handleClick = () => {
    refFile.current?.click();
  };

  useEffect(() => {
    setImages(files);
  }, [files]);

  return (
    <div className="container-upload">
      <input
        ref={refFile}
        type="file"
        accept="image/*"
        multiple
        onChange={(e) => handleSelectImage(e)}
        className="input-type-file"
      />
      <div className="btn-multi-upload" onClick={handleClick}>
        <FontAwesomeIcon icon={faImage} />Thêm ảnh
      </div>
      {slider.length > 0 &&
        <div className="images-select">
          {slider.map((image, index) => (
            <div key={`${image.id}-${index}`} className="item-image">
              <Image src={image.url} width={150} height={150} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                color="red"
                fontSize={22}
                onClick={() => handleDeleteImage(image.id)}
                className="cursor-pointer absolute top-[5px] right-[5px]"
              />
            </div>
          ))}
        </div>
      }
      {urlImages?.length > 0 &&
        <div className="images-select">
          {urlImages.map((image) => (
            <div key={image} className="item-image">
              <Image src={image} width={150} height={150} />
              <FontAwesomeIcon
                icon={faCircleXmark}
                color="red"
                fontSize={22}
                onClick={() => handleDeleteImageProps(image)}
                className="cursor-pointer absolute top-[5px] right-[5px]"
              />
            </div>
          ))}
        </div>
      }
    </div>
  )
};

export default memo(Upload);