import axios from "axios";
import { StatusCode } from "../utils/enum";
import { localClearStorage, localGetItem } from "../utils/storage";
import { useNavigate } from "react-router-dom";
import { Epath } from "../utils/Epath";

const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        Accepted: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'Access-Control-Allow-Origin': '*',
    },
});

axiosClient.interceptors.request.use((config) => {
    const token = localGetItem("token") && `Bearer ${localGetItem("token")}`;
    config.headers.Authorization = token;
    return config;
}, (error) => {
    return Promise.reject(error);
});

axiosClient.interceptors.response.use((response) => {
    return response;
}, async (error) => {
    if (error.response.status === StatusCode.Unauthorized) {
        localClearStorage();
        const navigate = useNavigate();
        navigate(Epath.LOGIN);
        return Promise.reject(error);
    }
    return Promise.reject(error);
})

export default axiosClient;