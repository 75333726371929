import { configureStore } from "@reduxjs/toolkit";
import spinSlice from "./features/spinSlice";
import authSlice from "./features/authSlice";
import profileSlice from "./features/profileSlice";
import loanSlice from "./features/loanSlice";

export const store = configureStore({
    reducer: {
        // Add reducer...
        spinSlice,
        authSlice,
        profileSlice,
        loanSlice,
    }
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;