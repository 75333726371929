import React, { useEffect, useState } from "react";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { getListLoanAsync } from "../../../store/features/loanSlice";
import { convertMoney } from "../../../utils/func";
import { Pagination, PaginationProps, TablePaginationConfig } from "antd";
import { ColorStateProject, LabelStateProject, StateProject } from "../../../utils/enum";
import dayjs from "dayjs";

type TableParams = {
   pagination?: TablePaginationConfig;
}

const RegisterMoney = () => {
   const dispatch = useAppDispatch();
   const listLoan = useAppSelector(state => state.loanSlice);
   const [tableParams, setTableParams] = useState<TableParams>({
      pagination: {
         current: 1,
         pageSize: 10
      }
   });

   const onChangePagination: PaginationProps['onChange'] = (page, pageSize) => {
      setTableParams({
         ...tableParams,
         pagination: {
            current: page,
            pageSize: pageSize
         }
      })
   };

   const getListLoan = () => {
      dispatch(getListLoanAsync({
         page: tableParams.pagination?.current && (tableParams.pagination?.current - 1),
         size: tableParams.pagination?.pageSize,
      }));
   };

   useEffect(() => {
      getListLoan();
   }, [JSON.stringify(tableParams)]);

   return (
      <div className="container-history-register w-[100%] max-w-[1024px] mt-[50px] px-[10px] box-border">
         <h3 className="mb-[20px]">Lịch sử hỗ trợ tài chính</h3>
         {listLoan.data.length > 0 ? (
            <>
               <div className="container-list-loan grid grid-cols-4 gap-4 mb-[20px]">
                  {listLoan.data.map((loan) => (
                     <div
                        key={loan.loan_application_id}
                        className="p-[10px] border-solid border-gray-200 border-[1px] rounded-[8px] shadow-xl"
                     >
                        <h2 className="text-center">{convertMoney(loan.loan_number)} USD</h2>
                        <h3 className="text-center">{loan.borrowed_time} tháng</h3>
                        <div className="text-[12px] mt-[10px]">Ngày vay: 
                           <span className="italic ml-[8px] text-[12px]">{loan.created_at && dayjs(loan.created_at).format("DD/MM/YYYY")}</span>
                        </div>
                        <div className="text-[12px] mt-[10px]">Trạng thái:
                           <span className="font-semibold italic ml-[8px] text-[12px]" 
                              style={{
                              color: loan.status === StateProject.STATE_APPROVE
                                 ? ColorStateProject.STATE_APPROVE
                                 : loan.status === StateProject.STATE_PROCESSING
                                    ? ColorStateProject.STATE_PROCESSING
                                    : ColorStateProject.STATE_REJECT
                           }}>
                              {loan.status === StateProject.STATE_APPROVE
                                 ? LabelStateProject.STATE_APPROVE
                                 : loan.status === StateProject.STATE_PROCESSING
                                    ? LabelStateProject.STATE_PROCESSING
                                    : LabelStateProject.STATE_REJECT}
                           </span>
                        </div>
                     </div>
                  ))}
               </div>
               <div className="h-[56px] flex items-center justify-end px-[20px]">
                  <Pagination
                     total={listLoan.total_item}
                     defaultCurrent={1}
                     pageSizeOptions={[10, 20, 30, 50]}
                     showSizeChanger={true}
                     onChange={onChangePagination}
                  />
               </div>
            </>
         ) : (
            <p>Bạn chưa có khoản vay nào.</p>
         )}
         <div className="h-[50px]"></div>
      </div>
   )
};

export default RegisterMoney;
