import  { useEffect,  useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import TitlePage from "../../../components/atoms/titlePage";
import { Button,  Image, Modal, Tag, message } from 'antd';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft} from "@fortawesome/free-solid-svg-icons";
import { Epath } from "../../../utils/Epath";
import { ColorStateProject, LabelStateProject, StateProject } from "../../../utils/enum";
import TextArea from "antd/es/input/TextArea";
import { confirmLoan, getLoan } from "../../../store/features/loanSlice";
import { convertMoney } from "../../../utils/func";

const LoanManagerUpdate = () => {
    const key = "messlogin";
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { loan_application_id } = useParams();
    const [rejectReason, setRejectReason] = useState("");
    const [errorReject, setErrorReject] = useState(false);
    const [modalReject, setModalReject] = useState(false);
    const loan = useAppSelector(state => state.loanSlice.loan);

    const getDetailProject = () => {
        dispatch(getLoan({ id: loan_application_id }))
    };

    const closeModalConfirm = () => {
        setModalReject(false);
    };

    const onChangeReason = (value: string) => {
        setErrorReject(!(value.length > 0));
        setRejectReason(value);
    };

    const showModalConfirm = () => {
        setRejectReason("");
        setErrorReject(false);
        setModalReject(true);
    };

    const onSubmitConfirm = (type: "reject" | "approve") => {
        const params = {
            id: loan?.loan_application_id,
            status: type === "reject" ? StateProject.STATE_REJECT : StateProject.STATE_APPROVE,
            reason: rejectReason
        };
        let error = false;
        if (type === "reject") {
            if (!(rejectReason.length > 0)) {
                setErrorReject(true);
                error = true;
            }
        }
        if (!error) {
            dispatch(confirmLoan(params)).then((res) => {
                message.open({ type: "success", content: res.payload.message, key, duration: 2 });
                closeModalConfirm();
                getDetailProject();
            }).catch((error) => {
                message.open({ type: "error", content: error.message, key, duration: 2 });
            })
        }
    };

    useEffect(() => {
        getDetailProject();
    }, []);

    return (
        <div className="h-full">
            <div className="flex items-center">
                <h2 className="ml-[20px]">
                    <FontAwesomeIcon icon={faArrowLeft} className="cursor-pointer" onClick={() => navigate(Epath.HOME)} />
                </h2>
                <TitlePage title="Chi tiết khoản vay" />
            </div>
            <div className="box-border" style={{ height: "calc(100% - 106px)" }}>
                <div className="h-full w-full overflow-auto box-border">
                    <div className="flex items-start gap-[20px] px-[20px]">
                        <div className="w-[40%]">
                            <h3 className="mb-[40px]">Thông tin người vay</h3>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Họ và tên: </h4> {loan?.customer_name}</div>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Ngày sinh: </h4> {loan?.birthday}</div>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Số điện thoại: </h4> {loan?.phone_number}</div>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Email: </h4> {loan?.email}</div>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Số tiền vay: </h4> {convertMoney(loan?.loan_number ? loan.loan_number : 0)} USD</div>
                            <div className="flex gap-[8px] mb-[10px]"><h4>Thời hạn: </h4> {loan?.borrowed_time} tháng</div>
                            <div className="flex gap-[8px] mb-[10px]">
                                <h4>Trạng thái phê duyệt:</h4>
                                <span className="px-[8px] py-[2px] rounded-[6px] text-[#ffffff] ml-[10px] text-[13px]"
                                    style={{
                                        backgroundColor: loan && loan.status !== undefined
                                            ? (loan.status === StateProject.STATE_APPROVE
                                                ? ColorStateProject.STATE_APPROVE
                                                : loan.status === StateProject.STATE_PROCESSING
                                                    ? ColorStateProject.STATE_PROCESSING
                                                    : ColorStateProject.STATE_REJECT)
                                            : ColorStateProject.STATE_PROCESSING
                                    }}
                                >
                                    {loan && loan.status !== undefined
                                        ? (loan.status === StateProject.STATE_APPROVE
                                            ? LabelStateProject.STATE_APPROVE
                                            : loan.status === StateProject.STATE_PROCESSING
                                                ? LabelStateProject.STATE_PROCESSING
                                                : LabelStateProject.STATE_REJECT)
                                        : LabelStateProject.STATE_PROCESSING
                                    }
                                </span>
                            </div>
                            {loan?.reason
                                && <span>
                                    <span className="font-semibold italic text-[red] text-[12px]">Lý do bị từ chối: </span>
                                    <span className="text-[13px]">{loan.reason}</span>
                                </span>
                            }
                        </div>
                        <div className="flex-1">
                            <h3 className="mb-[40px]">Giấy tờ tùy thân</h3>
                            <div className="w-full box-border flex flex-wrap gap-[10px]">
                                {loan?.file_keys.map((url) =>
                                    <Image key={url} src={url} width={200} height={200} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="h-[56px] flex justify-end items-center gap-[20px] px-[20px]">
                {loan?.status !== StateProject.STATE_REJECT && <Button type="primary" danger onClick={showModalConfirm}>Từ chối</Button>}
                {loan?.status !== StateProject.STATE_APPROVE && <Button type="primary" onClick={() => onSubmitConfirm("approve")}>Chấp thuận</Button>}
            </div>
            <Modal
                title="Từ chối khoản vay"
                open={modalReject}
                onCancel={closeModalConfirm}
                onOk={() => onSubmitConfirm("reject")}
                width={400}
                okText="Lưu"
                cancelText="Hủy bỏ"
            >
                <h4 className="text-center my-[30px]">Vui lòng nhập lý do từ chối khoản vay</h4>
                <TextArea
                    value={rejectReason}
                    rows={4}
                    placeholder="Lý do..."
                    status={errorReject ? 'error' : undefined}
                    onChange={(e) => { onChangeReason(e.target.value) }}
                />
                {errorReject && <p className='text-[red] mt-[4px] text-[12px]'>Yêu cầu nhập lý do</p>}
            </Modal>
        </div>
    )
};

export default LoanManagerUpdate;