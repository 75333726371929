import React, { memo, useEffect, useState } from "react";
import { Epath } from "../../../utils/Epath";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartSimple, faCircleArrowLeft, faCircleArrowRight, faMoneyCheckDollar } from "@fortawesome/free-solid-svg-icons";
import logo from "../../../assets/images/logo.png";
import { localGetItem } from "../../../utils/storage";
import { ROLE } from "../../../utils/enum";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

type TItem = {
    key: string;
    link: string;
    label: string;
    icon: IconDefinition;
}

const Sidebar = ({
    small, callBack
}: {
    small: boolean,
    callBack?: (type: string) => void;
}) => {
    const role = localGetItem("role");
    const [items, setItems] = useState<TItem[]>();
    const adminItems = [
        {
            key: Epath.HOME,
            link: Epath.HOME,
            label: "Quản lý các khoản vay",
            icon: faChartSimple
        }
    ];

    const userItems = [
        {
            key: Epath.HOME,
            link: Epath.HOME,
            label: "Đăng ký khoản vay",
            icon: faMoneyCheckDollar
        },
        {
            key: Epath.REGISTER_MONEY,
            link: Epath.REGISTER_MONEY,
            label: "Lịch sử khoản vay",
            icon: faMoneyCheckDollar
        }
    ];

    useEffect(() => {
        setItems(role === ROLE.ADMIN ? adminItems : userItems)
    }, [role]);

    const handleClick = () => callBack && callBack(small ? 'DEFAULT_SIZE' : 'SMALL_SIZE');

    return (
        <div className="w-full h-screen bg-sidebar text-white">
            <div className="flex items-center justify-center box-border h-[52px]">
                <img alt="logo" src={logo} width={36} height={36} />
            </div>
            <div className="box-border" style={{ height: "calc(100vh - 52px)", overflow: "hidden" }}>
                {items && items.map((item) =>
                    <NavLink
                        key={item.key}
                        to={item.link}
                        className={(
                            ({ isActive }) =>
                                isActive ? "text-white font-semibold bg-active-link"
                                    : "text-no-active hover:bg-active-link hover:text-white hover:font-semibold"
                        )}
                        style={{ height: "36px", width: "100%", display: "flex", alignItems: "center", boxSizing: "border-box" }}
                    >
                        <div className={`flex items-center w-full gap-[12px] text-[13px]
                            ${small ? "justify-center" : "pl-2"}`}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            <FontAwesomeIcon icon={item.icon} />
                            {!small && item.label}
                        </div>
                    </NavLink>
                )}
            </div>
            <div className="w-full relative bottom-[50px] flex justify-center">
                <FontAwesomeIcon
                    icon={!small ? faCircleArrowLeft : faCircleArrowRight}
                    style={{ fontSize: "24px", cursor: "pointer" }}
                    onClick={handleClick}
                />
            </div>
        </div>
    )
};

export default memo(Sidebar);