import React, { useEffect, useState } from "react";
import "./style.scss";
import { useAppDispatch, useAppSelector } from "../../../store/hook";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertMoney } from "../../../utils/func";
import { Button, Modal, Slider, SliderSingleProps, message } from "antd";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import ItemTimeLoan from "../../../components/atoms/itemTimeLoan";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { registerLoan } from "../../../store/features/loanSlice";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Field from "../../../components/common/input";
import { FormProvider, useForm } from "react-hook-form";
import { RegisterInformationForm } from "../../../utils/typeForm";

const marks: SliderSingleProps["marks"] = {
  5000: {
    style: {
      transform: "translateX(0%)",
    },
    label: "5,000",
  },
  300000: {
    style: {
      transform: "translateX(-100%)",
    },
    label: "300,000",
  },
};

const marks2: SliderSingleProps["marks"] = {
  1: {
    style: {
      transform: "translateX(0%)",
    },
    label: "1 tháng",
  },
  20: {
    style: {
      transform: "translateX(-100%)",
      whiteSpace: "nowrap"
    },
    label: "20 tháng",
  },
}

const HomeUser = () => {
  const key = "mess_register_loan";
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.profileSlice.data);
  const [money, setMoney] = useState(5000);
  const [borrowedTime, setBorrowedTime] = useState(3);
  const methods = useForm<RegisterInformationForm>({
    mode: "onSubmit",
    criteriaMode: "firstError",
    reValidateMode: "onChange",
  });

  const [isModalVisible, setIsModalVisible] = useState(false); // State variable to control modal visibility
  const [isChecked, setIsChecked] = useState(false); // Checkbox state

  // Function to handle button click and open the modal
  const handleOpenModal = () => {
    setIsModalVisible(true);
  };

  // Function to handle modal close
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const [faqs, setFaqs] = useState([
    {
      id: 1,
      question: "Có thể hủy yêu cầu vay online không?",
      answer:
        "Trước thời điểm xét duyệt vay thành công các bạn có thể hủy yêu cầu vay bất cứ lúc nào. Tuy nhiên, sau khi yêu cầu vay đã được xét duyệt vốn vay sẽ được giải ngân ngay tức thì và như vậy các bạn sẽ không thể hủy yêu cầu vay nữa. Vay tiền online thực sự đã mang tới nhiều thuận lợi cho khách hàng. Đó cũng là lý do mà dịch vụ này đang ngày càng có xu hướng “lên ngôi”. Khi có nhu cầu vay tiền nhanh online, hãy lựa chọn những app vay uy tín, đáng tin cậy mà Jeff Việt Nam đã chia sẻ để vay được khoản vay tốt với mức lãi suất thấp và hưởng nhiều chính sách ưu đãi.",
      isShow: false,
    },
    {
      id: 2,
      question: "Thanh toán trễ hạn thì sao?",
      answer:
        "Tốt nhất khi vay online các bạn nên cố gắng thanh toán sớm hoặc đúng hạn. Trường hợp thanh toán trễ hạn sẽ bị phạt một khoản phí nhất định. Khoản phí này cụ thể bao nhiêu tùy thuộc quy định của mỗi tổ chức tài chính.",
      isShow: false,
    },
    {
      id: 3,
      question: "Có thể thanh toán khoản vay sớm không?",
      answer:
        "Các công ty tài chính chấp nhận cho khách hàng được thanh toán khoản vay sớm nếu có điều kiện mà không áp dụng chính sách phạt.",
      isShow: false,
    },
    {
      id: 4,
      question: "Nếu vay tiền nhanh online không trả có sao không?",
      answer:
        "Các công ty tài chính cho vay online chủ yếu dựa vào uy tín của khách hàng để xét duyệt khoản vay và không có hợp đồng vay hay tài sản thế chấp gì. Vì vậy, nếu các bạn không chịu thanh toán khoản vay thì các tổ chức tài chính cũng không thể tiến hành khởi kiện được. Tuy nhiên, các bạn có thể sẽ thường xuyên bị gọi điện thoại nhắc nhở trả nợ khiến bạn khó mà tập trung làm việc được. Đồng thời, nếu sau này gặp khó khăn về tài chính cần vay mượn online thì tổ chức tín dụng hoàn toàn có thể từ chối khoản vay của bạn vì bạn đã bị liệt vào danh sách nợ xấu.",
      isShow: false,
    },
    {
      id: 5,
      question: "Vay tiền online cần lưu ý điều gì?",
      answer:
        "Khi có nhu cầu vay online trong ngày các bạn cần lưu ý tới 6 vấn đề sau: Một là, cân nhắc khả năng tài chính của bản thân trước khi đăng ký vay để. Tránh trường hợp vay nhưng không đủ khả năng chi trả. Hai là, chỉ nên vay đúng số tiền mình cần và vay vào mục đích thiết thực. Ba là, tìm hiểu kỹ các app/website cho vay online uy tín để tránh đăng ký vay nhầm các app/website cho vay lừa đảo, nặng lãi. Bốn là, sau khi đăng ký vay, có thể bạn sẽ nhận được điện thoại của nhân viên công ty tài chính để xác minh thông tin. Vì vậy, hãy chú ý điện thoại để không bỏ lỡ cuộc gọi và chắc chắn rằng các thông tin đã điền khi đăng ký vay là chính xác. Năm là, nên thanh toán tiền vay trước hạn từ 1 - 2 ngày để hệ thống kịp cập nhật.",
      isShow: false,
    },
    {
      id: 6,
      question: "Có nên dùng thông tin người khác để vay tiền không?",
      answer:
        "Các bạn tuyệt đối không nên sử dụng thông tin của người khác khi chưa được phép để vay tiền vì đây là một hành vi lừa đảo và vi phạm đạo đức, kể cả đó là người thân trong gia đình. Chỉ sử dụng thông tin cá nhân của bản thân để vay tiền với mục đích cụ thể.",
      isShow: false,
    },
    {
      id: 7,
      question: "Vay nhanh online có nhược điểm không?",
      answer:
        "Bất kỳ hình thức vay nào cũng có nhược điểm nhất định và vay nhanh online cũng vậy. Hình thức vay tiền này có 3 nhược điểm đó là: Khoản tiền vay không cao. Thời gian vay ngắn. Mức lãi suất so với vay thế chấp có phần cao hơn.",
      isShow: false,
    },
    {
      id: 8,
      question: "Dịch vụ vay trực tuyến hỗ trợ khách hàng khu vực nào?",
      answer:
        "Hình thức vay trực tuyến có điểm ưu việt hơn so với vay truyền thống đó là không cần gặp mặt vẫn có thể vay được tiền. Do đó, các bạn dù ở tỉnh thành nào, chỉ cần có điện thoại hoặc máy tính kết nối internet là có thể đăng ký vay ngay.",
      isShow: false,
    },
    {
      id: 9,
      question: "Vay tiền online không cần CMND/CCCD có được không?",
      answer:
        "Khi vay tiền online các công ty tài chính chỉ yêu cầu khách hàng cung cấp duy nhất một loại giấy tờ đó là CMND hoặc thẻ CCCD. Hình thức cho vay tiền này không cần gặp mặt, không yêu cầu tài sản thế chấp, không bắt chứng minh tài chính mà chủ yếu dựa vào sự uy tín của khách hàng. Vì vậy, nếu bạn không cung cấp CMND hoặc thẻ CCCD thì công ty tài chính không thể nắm được thông tin của người vay, không tiến hành xét duyệt vay được.",
      isShow: false,
    },
    {
      id: 10,
      question: "Thanh toán nợ vay online ở đâu?",
      answer:
        "Các ứng dụng cho vay online của các tổ chức tín dụng hiện nay đều có rất nhiều hình thức thanh toán nợ để tạo điều kiện thuận lợi cho khách hàng. Các bạn có thể lựa chọn thanh toán khoản vay của mình bằng cách: Chuyển khoản qua ngân hàng. Thanh toán qua ví điện tử: Viettel pay, Momo,... Thanh toán tại các điểm giao dịch thu hộ hoặc chi nhánh Viettel Post.",
      isShow: false,
    },
    {
      id: 11,
      question: "Có thể cùng lúc vay mượn online ở nhiều ứng dụng không?",
      answer:
        "Cùng lúc các bạn hoàn toàn CÓ THỂ vay tiền online tại nhiều ứng dụng khác nhau. Các tổ chức tín dụng vẫn sẽ duyệt khoản vay cho bạn nếu đáp ứng đủ điều kiện. Tuy nhiên, lưu ý rằng, hãy chỉ vay đúng số tiền mà bạn cần để giảm áp lực trả nợ và lãi về sau.",
      isShow: false,
    },
  ]);

  const onChangeSlider = (value: number) => {
    setMoney(value);
  };

  const onChangeMount = (value: number) => {
    setBorrowedTime(value);
  }

  const onSubmitLoan = () => {
    const params = {
      loan_number: money,
      borrowed_time: borrowedTime,
    };
    dispatch(registerLoan(params))
      .then((res) => {
        message.open({
          type: "success",
          content: res.payload.message,
          key,
          duration: 2,
        });
      })
      .catch((error) => {
        message.open({
          type: "error",
          content: "Đăng ký vay thất bại",
          key,
          duration: 2,
        });
      });
  };

  const toggleAnswer = (id: number) => {
    setFaqs(
      faqs.map((faq) => {
        if (faq.id === id) {
          return { ...faq, isShow: !faq.isShow };
        }
        return faq;
      })
    );
  };

  const handleCheckboxChange = (e: any) => {
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    methods.reset({ ...profile });
  }, []);

  return (
    <div className="container-register-money">
      <Modal open={isModalVisible} onCancel={handleModalClose} footer={null}>
        <FormProvider {...methods}>
          <form action="">
            <div className="mt-[20px]">
              <h3 className="title-loan text-center">Xác nhận thông tin vay tiền</h3>
              <div className="mt-[30px]">
                {borrowedTime && (
                  <div>
                    Số tiền thực nhận{" "}
                    <span className="font-semibold text-lg">{`${convertMoney(
                      money
                    )} USD`}</span>{" "}
                    và trả trong vòng
                    <span className="font-semibold text-red-600 text-lg">
                      {" "}
                      {borrowedTime} tháng
                    </span>
                    .
                  </div>
                )}
              </div>
              <Field
                name="full_name"
                type="text"
                label="Họ và tên"
                labelClass="required"
                placeholder="Họ và tên"
                disabled
                rules={{
                  required: {
                    value: true,
                    message: "Vui lòng nhập Họ và tên",
                  },
                }}
              />
              <Field
                name="birthday"
                type="text"
                label="Ngày sinh"
                labelClass="required"
                placeholder="Ngày sinh"
                disabled
                rules={{
                  required: {
                    value: true,
                    message: "Vui lòng nhập Ngày sinh",
                  },
                }}
              />
              <Field
                name="email"
                type="text"
                label="email"
                labelClass="required"
                placeholder="email"
                disabled
                rules={{
                  required: {
                    value: true,
                    message: "Vui lòng nhập email",
                  },
                }}
              />
              <Field
                name="phone_number"
                type="text"
                label="Số điện thoại"
                labelClass="required"
                placeholder="email"
                disabled
                rules={{
                  required: {
                    value: true,
                    message: "Vui lòng nhập Số điện thoại",
                  },
                }}
              />
              <h4 className="mb-[8px]">Giấy tờ tùy thân: </h4>
              <div className="w-[100%] box-border flex flex-wrap gap-[10px]">
                {profile?.file_keys?.map((image) => (
                  <img key={image} src={image} width={100} height={100} />
                ))}
              </div>
            </div>
            <div className="mt-4 flex items-center">
              <input
                type="checkbox"
                id="consentCheckbox"
                name="consentCheckbox"
                className="mr-2"
                checked={isChecked}
                onChange={handleCheckboxChange}
              />
              <label
                htmlFor="consentCheckbox"
                className="text-[12px] text-gray-400"
              >
                Bằng việc đăng ký khoản vay, Tôi xác nhận đã được Mcredit thông
                báo và Đồng ý cho phép Mcredit xử lý dữ liệu cá nhân của Tôi
                phục vụ mục đích cung cấp Sản phẩm, dịch vụ của Mcredit và các
                nội dung khác được nêu tại Điều khoản điều kiện xử lý dữ liệu cá
                nhân
              </label>
            </div>
            <Button
              className="mt-[50px] h-[50px]"
              type="primary"
              block
              onClick={onSubmitLoan}
              disabled={!isChecked} // Disable the button if checkbox is not checked
            >
              Gửi yêu cầu
            </Button>
          </form>
        </FormProvider>
      </Modal>
      <div className="flex bg-[#f0f4fe] container_body">
        <div className="infor-user box-border">
          <img
            className="image-component"
            alt=""
            loading="eager"
            id="w-node-_470852de-c786-7a16-4f41-0337a9dc2c92-7502076e"
            src="https://assets-global.website-files.com/6107d6546b656b27d809cd54/6522b7bf2cc3d6ebe886949b_6522b7972aec641b8d2866b6_loan-ads.webp"
          />
          <div className="p-5">
            <h2 className="mt-[20px]">
              Vay tiền online nhanh chóng và tiện lợi
            </h2>
            <div className="mt-[10px]">
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#581c87"
                fontSize={17}
              />{" "}
              Trả góp lên đến 24 tháng
            </div>
            <div className="mt-[10px]">
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#581c87"
                fontSize={17}
              />{" "}
              Duyệt vay siêu tốc 1 phút
            </div>
            <div className="mt-[10px]">
              <FontAwesomeIcon
                icon={faCircleCheck}
                color="#581c87"
                fontSize={17}
              />{" "}
              Không cần chứng minh thu nhập
            </div>
          </div>
        </div>
        <div className="infor-loan box-border ">
          <div className="w-[100%] rounded-[8px] bg-[#ffffff] shadow-lg p-[20px] box-border mb-[20px] ">
            <h2 className="text-center pb-[20px]">Ước tính khoản vay</h2>
            <div className="flex items-center justify-between">
              <div>
                <FontAwesomeIcon icon={faCaretRight} /> Bạn muốn vay
              </div>
              <h3>{`${convertMoney(money)} USD`}</h3>
            </div>
            <div className="px-[10px]">
              <Slider
                min={5000}
                max={300000}
                step={5000}
                marks={marks}
                onChange={onChangeSlider}
              />
            </div>
            <div className="flex items-center justify-between">
              <div>
                <FontAwesomeIcon icon={faCaretRight} /> Thời hạn vay
              </div>
              <h3>{`${borrowedTime} tháng`}</h3>
            </div>
            {/* <div className="mt-[34px]">
              <FontAwesomeIcon icon={faCaretRight} /> Thời hạn vay
            </div> */}
            <div className="px-[10px]">
              <Slider
                min={1}
                max={20}
                step={1}
                defaultValue={3}
                marks={marks2}
                onChange={onChangeMount}
              />
            </div>
            {/* <div className="mt-[10px] w-[100%] flex flex-wrap gap-1 items-center justify-between">
              <ItemTimeLoan
                label={"3 Tháng"}
                value={3}
                timeLoan={borrowedTime}
                setTimeLoan={setBorrowedTime}
              />
              <ItemTimeLoan
                label={"6 Tháng"}
                value={6}
                timeLoan={borrowedTime}
                setTimeLoan={setBorrowedTime}
              />
              <ItemTimeLoan
                label={"12 Tháng"}
                value={12}
                timeLoan={borrowedTime}
                setTimeLoan={setBorrowedTime}
              />
              <ItemTimeLoan
                label={"24 Tháng"}
                value={24}
                timeLoan={borrowedTime}
                setTimeLoan={setBorrowedTime}
              />
            </div> */}
            <Button
              className="mt-[50px] h-[50px]"
              type="primary"
              block
              onClick={handleOpenModal}
            >
              VAY NHANH
            </Button>
          </div>
        </div>
      </div>
      <div className="flex bank-component">
        <p className="text-center">
          {`Chúng tôi chỉ hợp tác với các đối tác tài chính tốt nhất tại Việt Nam.\n Do đó, bạn có thể chắc chắn rằng khoản vay mà bạn sẽ nhận được đến từ một công ty uy tín.`}
        </p>
      </div>
      <div className="logos">
        <img
          src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/65244667f9ed19d52964c38e_logo_1645778839158.webp"
          loading="eager"
          alt=""
          className="partner-logo"
        />
        <img
          src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/6524479b9c18b5e6ad2bd46d_download%20(8).webp"
          loading="eager"
          alt=""
          className="partner-logo"
        />
        <img
          src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/652446fa80807cbd9f4831a7_Logo_TPBank.svg"
          loading="eager"
          alt=""
          className="partner-logo"
        />
        <img
          src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/65244748a353a877a56c7758_TrueMoney-logo.png"
          loading="eager"
          alt=""
          className="partner-logo"
        />
        <img
          src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/625879fcf6c684929927dfca_Group%20746.svg"
          loading="eager"
          alt=""
          className="partner-logo"
        />
      </div>
      <div className="bg-[#f0f4fe] pt-4 pb-4">
        <h2 className="h2-copy">Jeff hoạt động như thế nào?</h2>
        <h3 className="heading-13">
          Jeff mang đến cho bạn những đề nghị vay tốt nhất trên thị trường
        </h3>
        <div className="body-step-loan">
          <div className="w-layout-grid grid-112">
            <div
              id="w-node-afa73aae-0a06-fd41-81a9-2b07c596186c-7502076e"
              className="box"
            >
              <h2 className="numb">1</h2>
              <h3 className="heading-14">Trò chuyện với Jeff</h3>
              <p className="paragraph-2">
                Tìm và so sánh sản phẩm theo nhu cầu của bạn
              </p>
            </div>
            <div className="box">
              <h2 className="numb">2</h2>
              <h3 className="heading-14">Nhận các đề nghị vay</h3>
              <p className="paragraph-2">
                Nhập thông tin cá nhân của bạn, bao gồm số điện thoại và email
              </p>
            </div>
            <div className="box">
              <h2 className="numb">3</h2>
              <h3 className="heading-14">Chọn &amp; nhận tiền</h3>
              <p className="paragraph-2">
                Cân nhắc kỹ giữa các đề nghị mà Jeff cung cấp, dựa vào thông tin
                và nhu cầu của bạn
              </p>
            </div>
            <div className="box">
              <h2 className="numb">4</h2>
              <h3 className="heading-14">Chọn đề nghị phù hợp</h3>
              <p className="paragraph-2">
                Chọn đề nghị phù hợp và chờ chấp thuận từ một trong số các đối
                tác uy tín của Jeff
              </p>
            </div>
          </div>
        </div>
        <div className="w-layout-blockcontainer max-width-1170">
          <h2 className="heading-140">Người dùng nói gì về Jeff</h2>
          <div className="w-layout-grid grid-112-copy">
            <div className="box">
              <img
                src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/6522b5746a63b8b3ab711f0c_friends.svg"
                loading="lazy"
                alt=""
                className="image-184"
              />
              <h3 className="w-condition-invisible">Đơn giản</h3>
              <h3>Uy tín</h3>
              <p className="paragraph-2">
                Chúng tôi đề cao uy tín và sự trung thực, vì niềm tin là nền
                tảng của mọi mối quan hệ bền vững. Chúng tôi chỉ làm việc với
                các đối tác đáng tin cậy và có giấy phép tại Việt Nam. Do đó,
                bạn có thể yên tâm rằng mọi sự lựa chọn của mình đều được bảo
                đảm an toàn.
              </p>
            </div>
            <div className="box">
              <img
                src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/6522b5745f6164bbe1ef81f9_loyalty.svg"
                loading="lazy"
                alt=""
                className="image-184"
              />
              <h3>Lấy khách hàng làm trọng tâm</h3>
              <p className="paragraph-2">
                Chúng tôi luôn đặt mong muốn của khách hàng là ưu tiên hàng đầu.
                Sự hài lòng của bạn chính là thành công của chúng tôi!
              </p>
            </div>
            <div className="box">
              <img
                src="https://assets-global.website-files.com/6107d6546b656bffc909cd26/6522b5747ea9a737dac8a882_umbrella%20(1).svg"
                loading="lazy"
                alt=""
                className="image-184"
              />
              <h3 className="w-condition-invisible">Nhanh chóng</h3>
              <h3>Minh bạch</h3>
              <p className="paragraph-2">
                Chúng tôi đảm bảo rằng mọi quy trình và dịch vụ tại Jeff đều
                minh bạch. Khách hàng sẽ được cung cấp đầy đủ mọi thông tin về
                đối tác vay, lãi suất cũng như các chi phí phát sinh (nếu có).
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="question-body">
        <div className="question">
          <h3>Câu hỏi thường gặp</h3>
          <div className="mt-4">
            {faqs.map((faq) => (
              <div key={faq.id} onClick={() => toggleAnswer(faq.id)}>
                <div className="icon-question">
                  <FontAwesomeIcon
                    color="#0147f2"
                    size="xl"
                    className="icon"
                    icon={faq.isShow ? faMinus : faPlus}
                  />
                  <p className="faq-q-text">{faq.question}</p>
                </div>
                <div className={`faq-answer ${faq.isShow ? "show" : ""}`}>
                  <p>{faq.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="footer-consent">
          <div className="footer-text">
            Jeff không phải đơn vị cung cấp cho vay và không phát hành các khoản vay. Dịch vụ của Jeff giúp đánh giá các đối tác vay uy tín với các sản phẩm tài chính đa dạng, thời gian trả nợ linh hoạt từ 91 đến 180 ngày, với lãi suất APR tối thiểu là 0% và tối đa là 20%. Jeff không tính phí sử dụng dịch vụ. Chi phí cuối cùng mà người vay phải trả phụ thuộc vào từng khoản vay. Người dùng sẽ nhận được thông tin đầy đủ và chính xác về APR, cũng như tất cả các khoản phí trước khi ký hợp đồng vay.Dưới đây là ví dụ về cách tính phí, lãi và số tiền gốc dựa trên khoản vay. Mỗi đơn vị cung cấp cho vay đều có các điều kiện riêng và sẽ thay đổi theo số tiền cho vay, kỳ hạn, APR% và người cho vay.<br />
            <br />Ví dụ: Khoản vay 6.000.000 đồng, Thời gian trả góp: 6 tháng (180 ngày), Phí tư vấn: 600.000 đồng, Phí dịch vụ: 1.360.000 đồng, Lãi (18.3%/năm): 540.000 đồng, Tổng số tiền bạn phải trả là 8.500.000 đồng.<br /><br />Bản quyền © Jeff. Đơn vị chủ quản: Công ty TNHH Jeff Việt Nam. Địa chỉ: 135 Nguyễn Thị Nhung, Khu Thi Thi Vương Phúc, Phường Thiên Bình Phước, Thành phố Thủ Đức, Thành phố Hồ Chí Minh, Việt Nam, số điện thoại: +84 9018 157 66. Email: info@jeff.vn, &nbsp;Mã số doanh nghiệp: 0316719954 do Sở KHĐT TP.HCM được cấp ngày 23/02/2021
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeUser;
