import { RegisterForm } from "../../../utils/typeForm";
import { Button, message } from "antd";
import { useAppDispatch } from "../../../store/hook";
import { register } from "../../../store/features/authSlice";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.scss";
import Field from "../../../components/common/input";
import { FormProvider, useForm } from "react-hook-form";
import logo from "../../../assets/images/logo.png";
import { useEffect, useState } from "react";
import { Epath } from "../../../utils/Epath";

const Register = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const key = "messlogin";
  const [registerOk, setRegisterOk] = useState(false);
  const methods = useForm<RegisterForm>({
    mode: 'onSubmit', criteriaMode: 'firstError', reValidateMode: 'onChange'
  });

  const onRegister = (value: RegisterForm) => {
    const params = {
      email: value.email,
      password: value.password
    };
    dispatch(register(params)).then((res) => {
      if (res.payload.errors) {
        const err = res.payload.errors;
        message.open({ type: "error", content: err[0], key, duration: 2 });
      } else {
        message.open({ type: "success", content: res.payload.message, key, duration: 2 });
        setRegisterOk(true);
      }
    })
  };

  useEffect(() => {
    registerOk && navigate(Epath.LOGIN);
  }, [registerOk]);

  return (
    <div className="container-register w-100 h-[100vh] flex flex-col items-center bg-gradient-to-r from-[#65799b] to-[#5e2563] pt-[70px] box-border">
      <img src={logo} alt="Logo" className="logo" />
      <div className="form-register xs:w-[350px] xm:w-[390px] box-border rounded-[8px] shadow-2xl flex flex-col items-center p-[20px]">
        <h3 className="text-center mt-[20px]">Đăng ký tài khoản</h3>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onRegister)} className="mt-[30px] w-full">
            <Field
              name="email"
              type="text"
              label="Tên đăng nhập"
              labelClass="required"
              placeholder="Tên đăng nhập"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập tên đăng nhập"
                }
              }}
            />
            <Field
              name="password"
              type="password"
              label="Mật khẩu"
              labelClass="required"
              autoComplete="none"
              placeholder="Mật khẩu"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập mật khẩu"
                }
              }}
            />
            <Field
              name="confirm-password"
              type="password"
              label="Mật khẩu"
              labelClass="required"
              autoComplete="none"
              placeholder="Xác nhận mật khẩu"
              rules={{
                required: {
                  value: true,
                  message: "Vui lòng nhập xác nhận mật khẩu"
                },
                validate: (value: string) => {
                  return methods.watch('password') ? value === methods.watch('password') || "Không khớp với mật khẩu" : null;
                }
              }}
            />
            <div className="flex items-center justify-between">
              <NavLink to="/login">Đăng nhập</NavLink>
              <NavLink to="/forgot-passoword">Quên mật khẩu</NavLink>
            </div>
            <Button
              className="h-[40px] mt-[20px]"
              type="primary"
              htmlType="submit"
              block
            >
              Đăng ký
            </Button>
          </form>
        </FormProvider>
      </div>
    </div>
  )
};

export default Register;